import React, { useRef, useState } from "react";
import apple from "../assets/svgs/apple.svg";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setShow } from "../redux/startSlice";
const StartScreen = () => {
  const [value, setValue] = useState(0);
  const ref = useRef(null);
  const disptach = useDispatch();

  
  useEffect(() => {
    const intervalId = setInterval(() => {
      setValue((prevValue) => {
        const newValue = prevValue + 10;
        if (newValue > 100) {
          clearInterval(intervalId);

          disptach(setShow(false));
          ref.current.style.display = "none";
          return 100;
        }
        return newValue;
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div
      ref={ref}
      className="bg-black flex flex-col justify-center items-center h-screen transition-all translate-y-0 z-[150]"
    >
      <img src={apple} />
      <div className="mt-20">
        <progress
          className="h-[5px]  bg-[#4c4c4c] rounded transition-all"
          value={value}
          max={100}
        ></progress>
      </div>
      <p className="text-white text-sm mt-10 text-center container px-5">
        Greetings! You are about to explore my personal website, which is a
        replica of my phone. <br />
        Just like using a iPhone, feel free to navigate and explore.
        <br />
        {/* If you get lost and don't know what to do click on the icon and it will
        provide you with a tour. Thank you. */}
      </p>
    </div>
  );
};

export default StartScreen;
