import React, { useEffect, useRef, useState } from "react";
import $ from "jquery";
import { ReactComponent as ControlCenterIcon } from "../assets/svgs/control.svg";
import { ReactComponent as Wifi } from "../assets/svgs/wifi.svg";
import { ReactComponent as Apple } from "../assets/svgs/apple_black.svg";
import { ReactComponent as Battery } from "../assets/svgs/battery.100.svg";
import { ReactComponent as Player } from "../assets/svgs/player.svg";
import { ReactComponent as Search } from "../assets/svgs/search.svg";
import { ReactComponent as Messages } from "../assets/svgs/messages.svg";
import ControlCenter, { MusicPlayer } from "./ControlCenter";
import { useSelector } from "react-redux";
import OutsideBounds from "./OutsideBounds";
import { toast } from "sonner";
const MenuBar = () => {
  const [show, setShow] = useState(false);
  const [showPlayer, setShowPlayer] = useState(false);
  const { settings, currentApp } = useSelector((state) => state.app);
  const ref = useRef();
  const playerRef = useRef();
  const triggerRef = useRef();
  const playerTriggerRef = useRef();

  let wrapper;

  useEffect(() => {
    wrapper = document.querySelector("#main-side-panel-wrapper");
  });
  useEffect(() => {
    renderTime();
  }, []);
  var clockVar = {};

  function renderTime() {
    let currentTime = new Date();
    clockVar.y = currentTime.getFullYear();
    clockVar.mth = currentTime.getMonth();
    clockVar.dt = currentTime.getDate();
    clockVar.d = currentTime.getDay();
    clockVar.h = currentTime.getHours();
    clockVar.m = currentTime.getMinutes();
    clockVar.s = currentTime.getSeconds();
    setTimeout(renderTime, 1000); //1000
    if (clockVar.h < 10) {
      clockVar.h = "0" + clockVar.h;
    }
    if (clockVar.m < 10) {
      clockVar.m = "0" + clockVar.m;
    }
    if (clockVar.s < 10) {
      clockVar.s = "0" + clockVar.s;
    }
    var months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    var monthsMin = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    var daysMin = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

    //let sTimeShowDate = $('.sTimeShowDate').hasClass('checked'), sTimeShowSeconds = $('.sTimeShowSeconds').hasClass('checked');
    let sTimeShowDate = $(".sTimeShowDate").is(":checked"),
      sTimeShowSeconds = $(".sTimeShowSeconds").is(":checked"),
      sDynamicWallpaper = $(".sDynamicWallpaper").is(":checked");

    var time = clockVar.h + ":" + clockVar.m;
    if (sTimeShowDate) {
      time =
        daysMin[clockVar.d] +
        " " +
        clockVar.dt +
        " " +
        monthsMin[clockVar.mth] +
        " " +
        time;
    }
    if (sTimeShowSeconds) {
      time = time + ":" + clockVar.s;
    }
    if (sDynamicWallpaper) {
      if (clockVar.h < 7 || clockVar.h > 18) {
        $("body").addClass("night");
      } else {
        $("body").removeClass("night");
      }
    }
    if (!sDynamicWallpaper) {
      $("body").addClass("night");
    }

    function nth(n) {
      return ["st", "nd", "rd"][((((n + 90) % 100) - 10) % 10) - 1] || "th";
    }
    $(".time").html(time);
    $(".sActionbarTime").html(time);
    $(".currentTime").html(clockVar.h + ":" + clockVar.m + ":" + clockVar.s);
    $(".day").html(daysMin[clockVar.d]);
    $(".dayNumber").html(clockVar.dt);
    $(".month").html(months[clockVar.mth]);
    $(".year").html(clockVar.y);
    $(".sPanelNotifications .date .today").html(
      days[clockVar.d] +
        ",<br>" +
        months[clockVar.mth] +
        " " +
        clockVar.dt +
        "<div class='ordinal'>" +
        nth(clockVar.dt) +
        "</div>"
    );
    $('[data-sGet="fullDate"]').html(
      days[clockVar.d] +
        ", " +
        months[clockVar.mth] +
        " " +
        clockVar.dt +
        ", " +
        clockVar.y
    );
  }

  const toggle = () => {
    if (wrapper) {
      if (show) {
        wrapper.style.right = "-40%";
      } else {
        wrapper.style.right = "3px";
      }
    }
    setShow(!show);
  };

  const togglePlayer = () => {
    setShowPlayer(!showPlayer);

    // if (showPlayer) {
    //   setShowPlayer(!showPlayer);
    // }else{
    // setShowPlayer(!showPlayer);

    // }
  };

  const closeOnClickOutside = () => {
    if (wrapper) {
      wrapper.style.right = "-40%";
    }
    setShow(false);
  };

  const closeOnClickOutsidePlayer = () => {
    setShowPlayer(false);
  };

  useClickOutside(ref, closeOnClickOutside, [triggerRef.current]);
  useClickOutside(playerRef, closeOnClickOutsidePlayer, [playerRef.current]);

  const quotes = [
    "Access the control center or player icon from the menu bar with a click.",
    "Click either the control center icon or the player icon in the menu bar.",
    "To access, click on the control center or the player icon in the menu bar.",
    "Click the control center or player icon on the menu bar to proceed.",
    "Easily access by clicking the control center or player icon in the menu bar."
];


  const controlCenterInfo = () => {
    toast(
      <div className="text-normal">
        <div className="flex justify-between mb-1 items-center">
          <div className="flex gap-1 items-center ">
            <Messages className="w-[18px] h-[18px]" />
            <p>MESSAGES</p>
          </div>
          <p>now</p>
        </div>
        <h3 className="font-bold mb-1 ">Sylvester</h3>
        <p className="leading-4">
          Click on the control center icon or the player icon on the menu bar
        </p>
      </div>
    );
  };
  return (
    <div className="actionbar font-light relative top-[-26px] flex ">
      <label className="item canCheck hidden">
        <input type="checkbox" className="sTimeShowDate" checked />
        Show date<span className="description">Day and month.</span>
      </label>
      <div className="leftActions flex gap-[18px]">
        <div className="item" data-trigger="sPanelLogOptions">
          {/* <img src={apple} className="w-[18px] h-[18px]" /> */}
          <Apple className="w-[18px] h-[18px]" />
        </div>
        <div className="group flex gap-[18px]">
          <div className="item bold" data-trigger="sPanelAppFinderOptions">
            {currentApp}
          </div>

          {settings.map((ele, i) => (
            <div
              className="item font-light"
              data-trigger="sPanelAppFinderOptionFile"
            >
              {ele}
            </div>
          ))}
        </div>
      </div>

      <div className="rightActions items-center gap-4">
        <div className="relative">
          <Player
            ref={playerTriggerRef}
            onClick={togglePlayer}
            className="w-[14px]"
          />
          <div
            ref={playerRef}
            className={`absolute w-[350px] top-[25px] right-0 ${
              showPlayer ? "block" : "hidden"
            }`}
          >
            <MusicPlayer />
          </div>
        </div>
        <Battery onClick={controlCenterInfo} className="w-[24px] fill-black" />
        <Wifi onClick={controlCenterInfo} className="w-[16px]" />
        <Search onClick={controlCenterInfo}  className="w-[12px] fill-black" />
        <div
          ref={triggerRef}
          onClick={toggle}
          className="item sActionbarNotifications  flex items-center"
          data-trigger="sPanelNotifications"
        >
          <ControlCenterIcon className="w-[14px] h-[14px] " />
        </div>
        <div
         onClick={controlCenterInfo}
          className="item sActionbarTime pr-[18px]"
          data-trigger="sPanelTime"
        ></div>
      </div>

      <div
        ref={ref}
        id="main-side-panel-wrapper"
        className="transition-all delay-100 side-panel-wrapper p-2 app-glass bg-red-100"
      >
        <ControlCenter />
      </div>
    </div>
  );
};

export default MenuBar;

const useClickOutside = (ref, callback, excludedElements = []) => {
  const handleClick = (e) => {
    let isExcluded = false;

    // Check if the click target is any of the excluded elements
    for (const excludedElement of excludedElements) {
      if (excludedElement.contains(e.target)) {
        isExcluded = true;
        break;
      }
    }

    if (!isExcluded && ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [ref, callback, excludedElements]);
};
