import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as Wifi } from "../assets/svgs/wifi.svg";
import { ReactComponent as WifiOff } from "../assets/svgs/wifi_off.svg";
import { ReactComponent as Bluetooth } from "../assets/svgs/bluetooth.svg";
import { ReactComponent as BluetoothOff } from "../assets/svgs/bluetooth_off.svg";
import { ReactComponent as AirDrop } from "../assets/svgs/airdrop.svg";
import { ReactComponent as Moon } from "../assets/svgs/moon.svg";
import { ReactComponent as Keyboard } from "../assets/svgs/keyboard.svg";
import { ReactComponent as Mirror } from "../assets/svgs/mirror.svg";
import { ReactComponent as Music } from "../assets/svgs/music.svg";
import { ReactComponent as Next } from "../assets/svgs/next.svg";
import { ReactComponent as Prev } from "../assets/svgs/prev.svg";
import { ReactComponent as Play } from "../assets/svgs/play.svg";
import { ReactComponent as Pause } from "../assets/svgs/pause.svg";
import { ReactComponent as Right } from "../assets/svgs/chevron-right.svg";
import { ReactComponent as Brightness } from "../assets/svgs/brightness.svg";
import { ReactComponent as Low } from "../assets/svgs/low.svg";
import { ReactComponent as Headphone } from "../assets/svgs/headphone.svg";
import { ReactComponent as StageManager } from "../assets/svgs/stage_manager.svg";
import { ReactComponent as ScreenMirroring } from "../assets/svgs/screen_mirroring.svg";

import $ from "jquery";
import forest from "../assets/music/forest.mp3";
import lorn from "../assets/music/lorn.mp3";
const ControlCenter = () => {
  const [display, setDisplay] = useState(50);
  const [sound, setSound] = useState(50);
  const [disturbMode, toggleDisturb] = useState(false);
  const [wifiMode, toggleWifi] = useState(true);
  const [bluetoothMode, toggleBluetooth] = useState(true);
  const [airdropMode, toggleAirdrop] = useState(true);

  const [stageMode, setStageMode] = useState(false);
  const [screenMirroring, setScreenMirroring] = useState(false);

  var low_brightness = document.getElementById("display-low");
  var high_brightness = document.getElementById("display-high");

  const disturb = document.getElementById("disturb");
  const wifi = document.getElementById("wifi");
  const bluetooth = document.getElementById("bluetooth");
  const airdrop = document.getElementById("airdrop");

  useEffect(() => {
    if (wifi) {
      if (wifiMode) {
        wifi.classList.add("bg-blue");
        wifi.classList.remove("bg-inactive");
      } else {
        wifi.classList.remove("bg-blue");
        wifi.classList.add("bg-inactive");
      }
    }
  }, [wifiMode]);

  useEffect(() => {
    $('input[type="range"]').change(function () {
      var val =
        ($(this).val() - $(this).attr("min")) /
        ($(this).attr("max") - $(this).attr("min"));

      $(this).css({
        "background-image":
          "-webkit-gradient(linear, left top, right top, " +
          "color-stop(" +
          val +
          ", #fff), " +
          "color-stop(" +
          val +
          ", #C5C5C5)" +
          ")",

        "border-radius": "16px",
      });
    });
  }, []);

  useEffect(() => {
    if (low_brightness && high_brightness) {
      if (display < 50) {
        low_brightness.style.display = "inline";
        high_brightness.style.display = "none";
        // low_brightness.style.transition = "display 0s, opacity 0.5s linear";
        // high_brightness.style.transition = "display 0s, opacity 0.5s linear";
      } else {
        low_brightness.style.display = "none";
        high_brightness.style.display = "inline";
        // low_brightness.style.transition = "display 0s, opacity 0.5s linear";
        // high_brightness.style.transition = "display 0s, opacity 0.5s linear";
      }
    }
  }, [display]);

  return (
    <>
      <div className="grid grid-cols-2 gap-2  text-[11px]  ">
        <div className="app-glass rounded-xl p-3 control-item">
          <div
            onClick={() => toggleWifi(!wifiMode)}
            className="grid grid-cols-[30px_auto] items-center gap-2"
          >
            <div
              id="wifi"
              className={`w-[30px] h-[30px] ${
                wifiMode ? "bg-blue" : "bg-inactive"
              } flex items-center justify-center rounded-full`}
            >
              {wifiMode ? (
                <Wifi className="w-[16px]" fill="#fff" />
              ) : (
                <WifiOff className="w-[16px]" fill="#fff" />
              )}
            </div>
            <div className="w-full flex justify-between items-center control-item-hov">
              <div className="">
                <p className="font-medium text-[12px]">Wi-Fi</p>
                <p className="text-[10px] mt-[-8px] text-subText">
                  {wifiMode ? "HUAWEI-5G-VA6P" : "Off"}
                </p>
              </div>
              <Right className="w-[6px] hidden" fill="white" />
            </div>
          </div>
          <div
            onClick={() => toggleBluetooth(!bluetoothMode)}
            className="grid grid-cols-[30px_auto] items-center gap-2 mt-1"
          >
            <div
              id="wifi"
              className={`w-[30px] h-[30px] ${
                bluetoothMode ? "bg-blue" : "bg-inactive"
              } flex items-center justify-center rounded-full`}
            >
              {bluetoothMode ? (
                <Bluetooth className="w-[16px]" fill="#fff" />
              ) : (
                <BluetoothOff className="w-[18px]" fill="white" />
              )}
            </div>
            <div className="w-full flex justify-between items-center control-item-hov">
              <div className="">
                <p className="font-medium text-[12px]">Bluetooth</p>
                <p className="text-[10px] text-subText mt-[-8px]">
                  {bluetoothMode ? "On" : "Off"}
                </p>
              </div>
              <Right className="w-[6px] hidden" fill="white" />
            </div>
          </div>
          <div
            onClick={() => toggleAirdrop(!airdropMode)}
            className="grid grid-cols-[30px_auto] items-center gap-2 mt-1"
          >
            <div
              className={`w-[30px] h-[30px] ${
                airdropMode ? "bg-blue" : "bg-inactive"
              } flex items-center justify-center  rounded-full`}
            >
              <AirDrop className="w-[16px]" fill="white" />
            </div>
            <div className="w-full flex justify-between items-center control-item-hov">
              <div className="">
                <p className="font-medium text-[12px]">AirDrop</p>
                <p className="text-[10px] text-subText mt-[-8px]">
                  {airdropMode ? "Contacts only" : "Off"}
                </p>
              </div>
              <Right className="w-[6px] hidden" fill="white" />
            </div>
          </div>
        </div>
        <div>
          <div
            id="disturb"
            onClick={() => toggleDisturb(!disturbMode)}
            className="grid grid-cols-[30px_auto] gap-2 control-item app-glass  p-3 rounded-xl"
          >
            <div
              className={`w-[30px] h-[30px] ${
                disturbMode ? "bg-blue" : "bg-inactive"
              } flex items-center justify-center  rounded-full`}
            >
              <Moon className="w-[16px]" fill="white" />
            </div>
            <div className="w-full flex items-center">
              <p className="font-medium text-[12px]">
                {disturbMode ? "Do Not Disturb" : "Focus"}
              </p>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-2">
            <div
              onClick={() => setStageMode(!stageMode)}
              className={` mt-2 control-item   p-3  rounded-xl control-item ${
                stageMode ? "bg-white" : "app-glass"
              }`}
            >
              <div className="mx-auto mb-[6px] flex items-center justify-center  rounded-full">
                <StageManager
                  className={`${stageMode ? "fill-[#007aff]" : "fill-white"} `}
                />
              </div>
              <div className="w-full ">
                <p
                  className={`${
                    stageMode ? "text-blue" : "text-white"
                  } text-center leading-3 `}
                >
                  Stage Manager
                </p>
              </div>
            </div>

            <div
              onClick={() => setScreenMirroring(!screenMirroring)}
              className={` mt-2 control-item   p-3  rounded-xl control-item ${
                screenMirroring ? "bg-white" : "app-glass"
              }`}
            >
              <div className="mx-auto mb-[6px] flex items-center justify-center  rounded-full">
                <ScreenMirroring
                  className={`${
                    screenMirroring ? "fill-[#007aff]" : "fill-white"
                  } `}
                />
              </div>
              <div className="w-full ">
                <p
                  className={`${
                    screenMirroring ? "text-blue" : "text-white"
                  } text-center leading-3 `}
                >
                  Screen Mirroring
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* display */}
      <div className="mt-2">
        <div className="control-item p-3 py-2 app-glass w-full rounded-xl relative ">
          <div className="mb-1 control-item-hov flex justify-between items-center">
            <p className="font-semibold text-[12px] ">Display</p>
            <Right className="w-[6px] hidden" fill="white" />
          </div>
          <Low
            fill="#434242"
            id="display-low"
            className="h-[11px] w-[11px] z-20 top-[39px] left-[17px] absolute hidden "
          />
          <Brightness
            id="display-high"
            fill="#434242"
            className="h-[11px] w-[11px] inline z-20 top-[39px] left-[17px] absolute "
          />
          <input
            onChange={(e) => setDisplay(e.target.value)}
            type="range"
            min="1"
            max="100"
            className="slider knob "
            id="myRange"
          />
        </div>
      </div>

      <div className="mt-2">
        <div className="control-item p-3 py-2 w-full app-glass rounded-xl relative">
          <div className="mb-1 control-item-hov flex justify-between items-center">
            <p className="font-semibold text-[12px] ">Sound</p>
            <Right className="w-[6px] hidden" fill="white" />
          </div>
          <Headphone
            fill="#434242"
            className="h-[11px] w-[11px] inline z-20 top-[39px] left-[17px] absolute "
          />
          <input
            type="range"
            min="1"
            max="100"
            // value="50"
            className="slider knob"
            id="myRange"
          />
        </div>
      </div>
      <div className="mt-2">
        <MusicPlayer />
      </div>
    </>
  );
};

export default ControlCenter;

export const MusicPlayer = () => {
  const audioRef = document.querySelector("audio");
  const sourceRef = document.querySelector("source");

  const sources = [
    {
      src: forest,
      title: "Friend, You Will Never Learn",
      artist: "Forest Swords",
      image: "https://f4.bcbits.com/img/a3887714731_16.jpg",
    },
    {
      src: lorn,
      title: "Acid Rain",
      artist: "Lorn",
      image: "https://f4.bcbits.com/img/a0919112757_16.jpg",
    },
  ];

  const [playMode, togglePlay] = useState(false);
  const [showPrev, setShowPrev] = useState(false);
  const [currentSong, setCurrentSong] = useState(sources[0]);


 


  useEffect(() => {
    if (audioRef) {
      if (playMode) {
        audioRef.play();
      } else {
        audioRef.pause();
      }
    }
  }, [playMode, audioRef]);

  useEffect(() => {
    if (audioRef) {
      if (playMode) {
        audioRef.play();
      } else {
        audioRef.pause();
      }
      if (currentSong.title === "Acid Rain") {
        setShowPrev(true);
      } else {
        setShowPrev(false);
      }
    }
  }, [currentSong]);


  const nextSong = () => {
    setCurrentSong(sources[1]);
    sourceRef.src = sources[1].src;
    audioRef.load(); //call this to just preload the audio without playing
    audioRef.play();
  };

  const prevSong = () => {
    setCurrentSong(sources[0]);
    sourceRef.src = sources[0].src;
    audioRef.load(); //call this to just preload the audio without playing
    audioRef.play();
  };

  return (
    <div className="">
      <div className="slidecontainer"></div>

      <div className="control-item p-3 app-glass w-full flex justify-between rounded-xl ">
        <div className="flex items-center">
          <div className="w-[40px] h-[40px] flex justify-center items-center bg-[#06060633] rounded">
            <img src={currentSong.image} className="rounded" />
            <audio>
              <source src={currentSong.src} type="audio/mpeg" />
              Your browser does not support the audio element.
            </audio>
            {/* <Music className="w-[30px] h-[30px]" /> */}
          </div>
          <div>
            <p className="font-semibold text-[12px]  ml-2">{currentSong.title}</p>
            <p className="text-[10px]  mt-[-8px] ml-2 text-subText">{currentSong.artist}</p>
          </div>
        </div>
        <div className="flex items-center ">
          {showPrev && (
            <Prev
              onClick={prevSong}
              fill="#bebdbd"
              className="w-[16px] h-[16px] "
            />
          )}
          {playMode ? (
            <Pause
              onClick={() => togglePlay(!playMode)}
              fill="#bebdbd"
              className="w-[14px] h-[14px] mr-5 ml-5 "
            />
          ) : (
            <Play
              onClick={() => {
                togglePlay(!playMode);
              }}
              fill="#bebdbd"
              className="w-[14px] h-[14px] mr-5 ml-5 "
            />
          )}
          {!showPrev && (
            <Next
              onClick={nextSong}
              fill="#bebdbd"
              className="w-[16px] h-[16px] "
            />
          )}
        </div>
      </div>
    </div>
  );
};
