import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  show: false,
  snotes: false,
};

export const windowSlice = createSlice({
  name: "window",
  initialState,
  reducers: {
    showWindow: (state, action) => {
      state.show = action.payload;
    },
    showNotes: (state, action) => {
      state.snotes = action.payload;
    },
  },
});

export const { showWindow, showNotes } = windowSlice.actions;

export default windowSlice.reducer;
