import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";

function useOutsideBounds(ref) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        alert("You clicked outside of me!");
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

function OutsideBounds(props) {
  const wrapperRef = useRef(null);
  useOutsideBounds(wrapperRef);

  return <div ref={wrapperRef}>{props.children}</div>;
}

OutsideBounds.propTypes = {
  children: PropTypes.element.isRequired,
};

export default OutsideBounds;
