import React, { useEffect } from "react";
import folder from "../assets/images/folder.png";
import DesktopIcons from "./Desktop/DesktopIcons";
import Window from "./Window";
import NotesWindow from "./NotesWindow";
import { showNotes } from "../redux/windowSlice";
import { setCurrentApp, setCurrentSettings } from "../redux/appSlice";
import { useDispatch, useSelector } from "react-redux";
import MenuBar from "./MenuBar";
import notes from "../assets/svgs/notes.svg";

const Desktop = () => {
  const icons = [
    {
      icon: folder,
      text: "Stuff",
    },
  ];

  const { snotes } = useSelector((state) => state.window);
  const disptach = useDispatch();
  // window.onSpotifyIframeApiReady = (IFrameAPI) => {
  //   let element = document.getElementById("embed-iframe");
  //   let options = {
  //     uri: "spotify:episode:7makk4oTQel546B0PZlDM5",
  //   };
  //   let callback = (EmbedController) => {};
  //   IFrameAPI.createController(element, options, callback);
  // };

  useEffect(()=>{
    disptach(showNotes(!snotes))
    disptach(setCurrentApp("Finder"))
    disptach(setCurrentSettings(["File", "Edit", "View", "Go", "Windows", "Help"]))
  },[])


  const handleNotes =()=>{
    disptach(showNotes(!snotes))
    disptach(setCurrentApp("Notes"))
    disptach(setCurrentSettings(["File", "Edit", "Format", "View", "Window", "Help"]))
  }


  return (
    <div className="no-select mp pt-4 relative  mt-[26px]">
      <MenuBar />
      {/* {icons.map((icon, i) => (
        <DesktopIcons key={i} {...icon} />
      ))} */}
      <div
        onDoubleClick={handleNotes}
        className="w-[100px] flex flex-col items-center mt-2"
      >
        <div className="pointer-events-auto flex-col justify-center items-center z-[15] absolute top-[0.5rem] right-[40px]">
          <img src={notes} className="w-[50px] h-[50px]  mx-auto  " />
          <p className="text-xs  text-white font-semibold mt-1">Resume</p>
        </div>
      </div>
      <div className="w-[100%] h-[100%]  flex justify-center  items-center absolute top-[-3px]    ">
        <Window />
        <NotesWindow />
      </div>
    </div>
  );
};

export default Desktop;
