import "./App.css";
import Dock from "./components/Dock";
import Desktop from "./components/Desktop";
import StartScreen from "./components/StartScreen";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
 
import MobileHome from "./components/Mobile/MobileHome";
import { Toaster } from "sonner";
function App() {
  const { show } = useSelector((state) => state.start);

  const [isMobile, setIsMobile] = useState(-1);

  const handleResize = () => {
    if (window.innerWidth < 900) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="">
      <Toaster
        position="top-right"
        toastOptions={{
          style: {
            backgroundColor: "hsl(0 0% 100% / 80%)",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            backdropFilter: "blur(0.5rem)",
            WebkitBackdropFilter: "blur(0.5rem)",
            opacity: 1,
            border: "none",
            borderRadius: 16,
          },
        }}
      />
      {
        isMobile === -1&&
        <div className="w-screen h-screen bg-black"></div>
      }
      {isMobile === true && (
        <>
          {show && <StartScreen />}
          <MobileHome />
        </>
      )}
      {!isMobile === true && (
        <div className="w-screen h-screen bg-[#111] grid grid-rows-[auto_60px] main-background  p-0">
          {show && <StartScreen />}
          <Desktop />
          <Dock />
        </div>
      )}
    </div>
  );
}

export default App;
