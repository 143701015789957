import React, { useEffect, useState } from "react";
import { ReactComponent as Phone } from "../../assets/svgs/phone.svg";
// import { ReactComponent as Safari } from "../../assets/svgs/safari.svg";
import { ReactComponent as Messages } from "../../assets/svgs/messages.svg";
import { ReactComponent as WhatsApp } from "../../assets/svgs/whatsapp.svg";

import { ReactComponent as Mail } from "../../assets/svgs/mail.svg";
import { ReactComponent as AppStore } from "../../assets/svgs/appstore.svg";
import { ReactComponent as Settings } from "../../assets/svgs/settings-mobile.svg";
import { ReactComponent as Photos } from "../../assets/svgs/photos-mobile.svg";
import { ReactComponent as Notes } from "../../assets/svgs/notes-icon.svg";
import { ReactComponent as Camera } from "../../assets/svgs/camera.svg";
import { ReactComponent as Book } from "../../assets/svgs/book.svg";
import { ReactComponent as Back } from "../../assets/svgs/chevron-back.svg";
import { ReactComponent as Options } from "../../assets/svgs/ellipsis.circle.svg";
import { ReactComponent as FullScreen } from "../../assets/svgs/fullscreen.svg";
import { ReactComponent as Battery } from "../../assets/svgs/battery.100.svg";
import { ReactComponent as Search } from "../../assets/svgs/search.svg";
import $ from "jquery";
import { Toaster, toast } from "sonner";

const MobileHome = () => {
  const [showNotes, setShowNotes] = useState(false);
  const [isFull, setIsFull] = useState(false);

  const apps = [
    {
      icon: <Photos className="w-[75px] h-[75px]" />,
      name: "Photos",
    },
    {
      icon: <Mail className="w-[75px] h-[75px]" />,
      name: "Mail",
    },
    {
      icon: <AppStore className="w-[75px] h-[75px]" />,
      name: "App Store",
    },
    {
      icon: <Settings className="w-[75px] h-[75px]" />,
      name: "Settings",
    },
    {
      icon: <Camera className="w-[75px] h-[75px]" />,
      name: "Camera",
    },
    {
      icon: <Book className="w-[75px] h-[75px]" />,
      name: "Book",
    },
    {
      icon: <FullScreen className="w-[75px] h-[75px] p-[3px]" />,
      name: "FullScreen",
    },
  ];

  useEffect(() => {
    var clockVar = {};

    let currentTime = new Date();
    clockVar.y = currentTime.getFullYear();
    clockVar.mth = currentTime.getMonth();
    clockVar.dt = currentTime.getDate();
    clockVar.d = currentTime.getDay();
    clockVar.h = currentTime.getHours();
    clockVar.m = currentTime.getMinutes();
    clockVar.s = currentTime.getSeconds();

    if (clockVar.h < 10) {
      clockVar.h = "0" + clockVar.h;
    }
    if (clockVar.m < 10) {
      clockVar.m = "0" + clockVar.m;
    }
    if (clockVar.s < 10) {
      clockVar.s = "0" + clockVar.s;
    }

    var time = clockVar.h + ":" + clockVar.m;

    $("#mobile-date").html(clockVar.dt);
    $("#mobile-time").html(time);

    var days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    $("#mobile-day").html(days[clockVar.d]);
    let fullDate = clockVar.dt + "/" + clockVar.mth + "/" + clockVar.y;
    $("#mobile-date-date").html(fullDate);
  }, [isFull, showNotes]);


  var elem = document.documentElement;

  const toggleFullscreen = (name) => {
    if (name === "FullScreen") {
      setIsFull((isFull) => !isFull);
      !isFull ? elem.requestFullscreen() : document.exitFullscreen();
    } else {
      toast(
        <div className="text-normal">
          <div className="flex justify-between mb-1 items-center">
            <div className="flex gap-1 items-center ">
              <Messages className="w-[18px] h-[18px]" />
              <p>MESSAGES</p>
            </div>
            <p>now</p>
          </div>
          <h3 className="font-bold mb-1 ">Sylvester</h3>
          <p className="leading-4">
            Click on the Notes app to view my resume or the fullscreen app icon
            to enter fullscreen mode. 
          </p>
        </div>
      );
    }
  };
  return (
    <div className="">
    
      {!showNotes ? (
        <div className="mobile-bg">
          {isFull ? (
            <div className="flex justify-between items-center text-white p-4">
              <p id="mobile-time" className="font-semibold text-[18px]"></p>
              <div className="flex justify-evenly items-start gap-2">
                {/* <Wifi className="w-[18px] h-[18px] fill-white"/> */}

                <Battery className="w-[px] h-[18px] fill-white" />
              </div>
            </div>
          ) : null}
          <div className="p-4 pt-12">
            <div className="grid grid-cols-2 gap-5">
              <div
                onClick={() => setShowNotes(true)}
                className="h-auto w-full bg-white  rounded-3xl flex flex-col"
              >
                <div className="notes-gradient h-auto w-full rounded-tl-3xl rounded-tr-3xl px-5 py-4">
                  <span className="flex gap-4 items-center">
                    <Notes className="w-[20px] h-[20px]" />
                    <span className="text-white text-sm">Notes</span>
                  </span>
                </div>
                <div className="border-dotted border-b-[#AAAAAA] border-b h-[6px]"></div>
                <div className="px-4 py-3 flex flex-col h-full">
                  <div className="flex-1">
                    <p className="text-[0.8125rem] uppercase font-bold text-black tracking-tighter">
                      RESUME
                    </p>
                    <p className="text-xs leading-4 mt-1 text-[#7d7d7d]">
                      Sylvester <br /> Asare Sarpong
                    </p>
                  </div>
                  <div>
                    <p
                      id="mobile-date-date"
                      className="text-xs leading-4 pt-0 text-[#7d7d7d]"
                    >
                      3/4/22
                    </p>
                    <p></p>
                  </div>
                </div>

                {/* <NotesHead/> */}
              </div>
              <div className=" w-full bg-white rounded-3xl p-5">
                <p
                  id="mobile-day"
                  className="text-xs uppercase font-bold text-[#FF3B30] tracking-tighter"
                >
                  Monday
                </p>
                <p id="mobile-date" className="text-[48px] mt-[-13px]">
                  25
                </p>
                <p className="text-xs leading-4 my-3 text-[#7d7d7d]">
                  No more <br /> events today
                </p>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-5 mb-5">
              <p className="text-center mt-1 text-xs text-white font-medium">
                Notes
              </p>
              <p className="text-center mt-1 text-xs text-white font-medium">
                Calender
              </p>
            </div>

            <div className="grid grid-cols-4 gap-5 app-icons">
              {apps.map((app, i) => (
                <div
                  onClick={() => toggleFullscreen(app.name)}
                  key={i}
                  className="w-full h-full   justify-center flex flex-col items-center"
                >
                  {app.icon}
                  <p className="text-center mt-0 text-xs text-white font-medium">
                    {app.name}
                  </p>
                </div>
              ))}
            </div>
          </div>
          <div className="absolute bottom-2 w-full mobile-dock-wrapper">
            <div className="mb-5 w-full flex justify-center items-center  ">
              <div className="flex items-center gap-1 mobile-dock-inner  w-fit rounded-xl px-[8px] py-[5px]">
                <Search className="w-[10px] h-[10px] fill-white" />
                <div className="text-xs font-medium text-white">Search</div>
              </div>
            </div>
            <div className="w-full   px-2">
              <div class="mobile-dock-inner rounded-[35px]">
                <div class="mobile-dock-icons">
                  <Phone className="w-[75px] h-[75px]" />
                  {/* <Safari className="w-[75px] h-[75px]" /> */}
                  <Messages className="w-[75px] h-[75px]" />
                  <WhatsApp className="w-[75px] h-[75px]" />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="bg-white h-screen overflow-y-scroll  w-screen p-3 pt-4">
          <div className="overflow-y-auto ">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-2">
                <Back className="fill-gold" />
                <p className="text-gold">All iCloud</p>
              </div>
              <div className="flex items-center gap-5">
                <Options className="fill-gold" />
                <button
                  onClick={() => setShowNotes(false)}
                  className="text-gold p-0"
                >
                  Close
                </button>
              </div>
            </div>
            <div className="px-4">
              <div className="mt-5">
                <h2 className="text-[1rem] font-bold">
                  SARPONG SYLVESTER ASARE
                </h2>
                <h2 className="text-[1rem] font-bold">SOFTWARE ENGINEER</h2>
                <h2 className="text-[1rem] font-bold">
                  GITHUB:{" "}
                  <a
                    href="https://github.com/Tylerasa"
                    className="text-sm text-gold font-normal underline underline-offset-[3px]"
                  >
                    https://github.com/Tylerasa
                  </a>
                </h2>
                <h2 className="text-[1rem] font-bold">
                  EMAIL:{" "}
                  <a
                    href="mailto:sarpongasaresylvester@gmail.com"
                    className="text-sm text-gold font-normal underline underline-offset-[3px]"
                  >
                    sarpongasaresylvester@gmail.com
                  </a>
                </h2>
                <h2 className="text-[1rem] font-bold">
                  WEBSITE:{" "}
                  <a
                    href="https://www.onlysly.com"
                    className="text-sm text-gold font-normal underline underline-offset-[3px]"
                  >
                    https://www.onlysly.com
                  </a>
                </h2>
                <h2 className="text-[1rem] font-bold">
                  LINKEDIN:{" "}
                  <a
                    href="https://www.linkedin.com/in/sylvester-sarpong-asare/"
                    className="text-sm text-gold font-normal underline underline-offset-[3px]"
                  >
                    https://www.linkedin.com/in/sylvester-sarpong-asare/
                  </a>
                </h2>
              </div>
              {/* education */}
              <div className="mt-5">
                <h1 className="w-full text-[1rem] font-bold uppercase  border-b mb-2">
                  Education
                </h1>
                <p className=" text-sm font-semibold">
                  Kwame Nkrumah University of Science and Technology, Ashanti
                  Region, Ghana
                </p>
                <p className="text-sm  ">Bachelor of Science</p>
                <p className="text-sm  text-gray">September 2021</p>
              </div>

              {/* technical skills */}

              <div className="mt-5">
                <h1 className="w-full text-[1rem] font-bold uppercase  border-b mb-2">
                  TECHINAL SKILLS
                </h1>
                <p className="font-bold">
                  LANGUAGES:{" "}
                  <span className="text-sm font-normal">
                    JavaScript , Typescript , Python , PHP, CSS, SQL.
                  </span>
                </p>
                <p className="font-bold">
                  FRAMEWORKS:{" "}
                  <span className="text-sm font-normal">
                    ReactJS, VueJS, Django, Laravel, NodeJS, NestJS, NextJS,
                    Tailwind
                  </span>
                </p>
                <p className="font-bold">
                  DATABASE:{" "}
                  <span className="text-sm font-normal">
                    MongoDB, MySQL, Firestore, DynamoDB, PostgreSQL .
                  </span>
                </p>
                <p className="font-bold">
                  AUTOMATION AND DEVOPS:{" "}
                  <span className="text-sm font-normal">
                    Docker, Kubernetes, Ansible, Terraform, Github Actions,
                    Stackstorm, ArgoCD.
                  </span>
                </p>
                <p className="font-bold">
                  OTHER SKILLS:{" "}
                  <span className="text-sm font-normal">
                    GraphQL, Redux Toolkit, deck.gl, React Native, Jest,
                    Cypress, TypeORM.
                  </span>
                </p>
              </div>

              <div className="mt-5">
                <h1 className="w-full text-[1rem] font-bold uppercase  border-b mb-2">
                  WORK EXPERIENCE
                </h1>
                <div>
                  <p className=" text-sm font-semibold">TIA Cloud, Accra</p>
                  <p className="text-sm  ">Midlevel DevOps Engineer</p>
                  <p className="text-sm  text-gray">October 2023 - Present</p>

                  <div className="text-sm mt-1">
                    • Successfully developed Stackstorm packs to automate the
                    process of Docker image creation and seamlessly hand over
                    control to ArgoCD. Which streamlined our containerization
                    procedures but has also significantly enhanced deployment
                    efficiency while adhering to the principles of GitOps.
                  </div>
                </div>

                <div className="mt-5">
                  <p className=" text-sm font-semibold">Farmerline, Accra</p>
                  <p className="text-sm  ">Software Engineer</p>
                  <p className="text-sm  text-gray">June 2022 - October 2023</p>

                  <div className="text-sm mt-1">
                    <p>
                      • Optimized database efficiency through the seamless
                      migration from MySQL to MongoDB, enabling greater
                      flexibility in accommodating dynamic document schema
                      changes.
                    </p>
                    <p className="mt-1">
                      • Enhanced the speed of user activity log migration by
                      implementing a highly efficient scheduled job, executed at
                      midnight, specifically designed to seamlessly transfer
                      logs from MYSQL to MongoDB. This strategic approach
                      significantly accelerated the caching process, ensuring
                      swift retrieval and access to crucial user activity data.
                    </p>
                    <p className="mt-1">
                      • Successfully engineered an advanced implementation of
                      the K-Nearest Neighbors (KNN) algorithm, specifically
                      designed to effectively detect and eliminate outlier
                      coordinates in agricultural land datasets.
                    </p>
                    <p className="mt-1">
                      • Spearheaded the development of a cutting-edge real-time
                      dashboard, leveraging WebSockets and Next.js, to monitor
                      and visualize all activities occurring on Mergdata, the
                      flagship platform of Farmerline. Effectively utilized
                      graph-based data representations to provide a
                      comprehensive and visually rich display of crucial
                      information.
                    </p>
                  </div>
                </div>

                <div className="mt-5">
                  <p className=" text-sm font-semibold">Wi-Flix, Amsterdam</p>
                  <p className="text-sm  ">Software Engineer</p>
                  <p className="text-sm  text-gray">
                    September - December 2022
                  </p>

                  <div className="text-sm mt-1">
                    • Optimized database management and advanced user activity
                    tracking by orchestrating the seamless migration of all
                    Firestore collections and Revidd data to a PostgreSQL
                    instance hosted on Google Cloud Platform (GCP). The
                    integration with Google Locker empowered comprehensive data
                    visualization capabilities across all Wi-Flix platforms,
                    including video, mobile, and web, enabling efficient
                    monitoring and analysis of user interactions.
                  </div>
                </div>

                <div className="mt-5">
                  <p className=" text-sm font-semibold">
                    FillyCoder, Amsterdam
                  </p>
                  <p className="text-sm  ">Fullstack Developer</p>
                  <p className="text-sm  text-gray">March - July 2022</p>

                  <div className="text-sm mt-1">
                    • Enhanced recruitment efficiency through a comprehensive
                    redesign of the Care in Touch Home Agency website,
                    incorporating a dedicated page specifically tailored for
                    seamless employment applications. The revamped website now
                    provides a user-friendly interface that optimizes the
                    application process, streamlining candidate acquisition and
                    ensuring a more efficient recruitment workflow.
                  </div>
                </div>

                <div className="mt-5">
                  <p className=" text-sm font-semibold">BøthOfUs, Stockholm</p>
                  <p className="text-sm  ">
                    Fullstack Developer and Mobile Developer
                  </p>
                  <p className="text-sm  text-gray">
                    November 2021 - Feburary 2022
                  </p>

                  <div className="text-sm mt-1">
                    • Optimized inventory management processes and enhanced
                    accessibility for farmers through the development of a
                    tailored solution. This solution enables seamless storage of
                    inventory data from Excel into Firestore, facilitating
                    real-time access to crucial inventory statistics. By
                    simplifying the data transfer process and leveraging
                    Firestore's capabilities, farmers can efficiently manage
                    their inventory and make informed decisions based on
                    up-to-date information.
                  </div>
                </div>

                <div className="mt-5">
                  <p className=" text-sm font-semibold">MicroBis, Kumasi</p>
                  <p className="text-sm  ">Frontend Developer</p>
                  <p className="text-sm  text-gray">October 2020 - July 2021</p>

                  <div className="text-sm mt-1">
                    <p>
                      • Elevated the usability and performance of an AI-powered
                      backend system by architecting an intuitive user
                      interface. This interface efficiently analyzes
                      user-supplied parameters and employs predictive algorithms
                      to analyze and predict isolates. The developed solution
                      enhances user experience, empowering users to leverage the
                      system's capabilities in a user-friendly manner while
                      benefiting from accurate predictions and insights.
                    </p>
                    <p className="mt-1">
                      • Advanced data visualization capabilities and delivered
                      comprehensive insights by constructing a cutting-edge
                      deck.gl dashboard. Leveraging geographical data, this
                      dashboard effectively clusters and highlights the most
                      prevalent isolates, offering a visually compelling
                      representation of patterns and trends. By employing
                      deck.gl's powerful visualization library, the dashboard
                      enables users to gain deeper insights into the data,
                      facilitating informed decision-making and enhanced
                      understanding of the most common isolates.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="h-[100px]"></div>
        </div>
      )}
    </div>
  );
};

export default MobileHome;
