import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  show: true,
};

export const startSlice = createSlice({
  name: "start",
  initialState,
  reducers: {
    setShow: (state, action) => {
      state.show = action.payload;
    },
  },
});

export const { setShow } = startSlice.actions;

export default startSlice.reducer;
