import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentApp: "Finder",
  settings: ["File", "Edit", "View", "Go", "Windows", "Help"]
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setCurrentApp: (state, action) => {
      state.currentApp = action.payload;
    },
    setCurrentSettings: (state, action) => {
      state.settings = action.payload;
    },
  },
});

export const { setCurrentApp, setCurrentSettings } = appSlice.actions;

export default appSlice.reducer;
