import React, { useEffect, useState } from "react";
import back from "../assets/images/back.png";
import { ReactComponent as Search } from "../assets/svgs/search.svg";
import { ReactComponent as Triangles } from "../assets/svgs/triangles.svg";
import { ReactComponent as Times } from "../assets/svgs/times.svg";
import { ReactComponent as Minus } from "../assets/svgs/minus.svg";
import mic from "../assets/images/mic.png";
import more from "../assets/images/more.png";
import enlarge from "../assets/images/enlarge.png";
import compress from "../assets/images/compress.png";
import list from "../assets/images/list.png";
import camera from "../assets/images/camera.png";
import create from "../assets/images/create.png";
import save from "../assets/images/save.png";
import plus from "../assets/images/plus.png";
import subtract from "../assets/images/subtract.png";
import close from "../assets/images/close.png";
import right_arrow from "../assets/images/right_arrow.png";
import left_arrow from "../assets/images/left_arrow.png";
import { useDispatch, useSelector } from "react-redux";
import $ from "jquery";
import { showNotes } from "../redux/windowSlice";
import { setCurrentApp, setCurrentSettings } from "../redux/appSlice";
const NotesWindow = () => {
  const { snotes } = useSelector((state) => state.window);
  const window = document.querySelector("#notes");

  const [fullScreen, toggleFullScreen] = useState(false);
  const disptach = useDispatch();
  
  useEffect(() => {
    if (window) {
      if (snotes) {
        window.classList.add("notes-window");
        window.classList.remove("notes-window--active");
        window.classList.remove("notes-window--minimized");

        window.classList.remove("notes-window--closed");
      } else {
        window.classList.add("notes-window--minimized");
      }
    }
  }, [snotes]);

  const minimize = () => {
    window.classList.add("notes-window--minimized");
    disptach(showNotes(!snotes))
    disptach(setCurrentApp("Finder"))
    disptach(setCurrentSettings(["File", "Edit", "View", "Go", "Windows", "Help"]))
  };

  const maximize = () => {
    if (!$(window).hasClass("notes-window--maximized")) {
      window.classList.add("notes-window--maximized");
    } else {
      window.classList.remove("notes-window--maximized");
      window.classList.add("notes-window");
    }
  };

  const cancel = () => {
    if (window) {
      window.classList.add("notes-window--closed");
      window.classList.remove("notes-window--active");
      window.classList.remove("notes-window");

      disptach(showNotes(!snotes))
      disptach(setCurrentApp("Finder"))
      disptach(setCurrentSettings(["File", "Edit", "View", "Go", "Windows", "Help"]))
    }
  };

  const toggleScreen = () => {
    toggleFullScreen(!fullScreen);
  };

  return (
    <div
      id="notes"
      className={`w-[900px]  z-[100] hidden bg-windowHeader h-[500px] absolute rounded-xl font-normal`}
    >
      <div className="flex  py-2 border-b border-b-windowBorder fixed w-full rounded-tl-xl rounded-tr-xl bg-white z-10 items-center">
        <div className="flex items-center p-2">
          <span
            onClick={cancel}
            className="w-[13px] h-[13px] bg-close rounded-full  flex items-center justify-center window-control"
          >
            <Times className="w-[6px] h-[6px] hidden" />
          </span>
          <span
            onClick={minimize}
            className="w-[13px] h-[13px] bg-minimise  rounded-full mx-2 flex items-center justify-center window-control"
          >
            <Minus className="w-[6px] h-[6px] hidden" />
          </span>
          <span
            onClick={maximize}
            className="w-[13px] h-[13px] bg-maximise  rounded-full flex items-center justify-center   window-control"
          >
            {/* <img src={plus} className="w-[8px] h-[8px] hidden" /> */}
            {/* <Triangle className="w-[6px] h-[6px] rotate-180" /> */}
            <Triangles className="w-[6px] h-[6px] hidden" />
          </span>
        </div>
      </div>

      <div
        className="pt-10 h-[100%]"
        style={{ height: "-webkit-fill-available" }}
      >
        <div
          className={`h-[100%] ${
            fullScreen ? "block" : "grid"
          } grid-cols-[30%_70%] rounded-tl-xl   `}
          // h-full
        >
          <div
            className={`bg-sidebarBg overflow-y-auto ${
              fullScreen ? "hidden" : "block"
            }   p-3  border-r border-r-windowBorder no-select`}
          >
            <p className="py-1 px-2 text-[13px] font-semibold">Today</p>
            <div className="">
              <div className="bg-white rounded-md mt-4 ">
                <div className="pt-3 pl-6 hover:bg-gold hover-cursor hover:rounded-t-md note-item">
                  <div className="pb-2  border-b border-b-[#eeeeec]">
                    <h3 className="text-[13px] font-semibold">My resume</h3>
                    <p className="text-xs text-gray">Sylvester Asare Sarpong</p>
                  </div>
                </div>
                <div className="pt-3 pl-6 hover:bg-gold hover-cursor note-item">
                  <div className="pb-2  border-b border-b-[#eeeeec]">
                    <h3 className="text-[13px] font-semibold">Secret Stuff</h3>
                    <p className="text-xs text-gray">
                      My notes app doesn't look like this, I just made some
                      creative choices
                    </p>
                  </div>
                </div>
                <div className="pt-3 pl-6 hover:bg-gold hover-cursor note-item">
                  <div className="pb-2  border-b border-b-[#eeeeec]">
                    <h3 className="text-[13px] font-semibold">
                      My thoughts on random stuff
                    </h3>
                    <p className="text-xs text-gray">
                      Not deep but deep(don't take them serious)
                    </p>
                  </div>
                </div>
                <div className="pt-3 pl-6 hover:bg-gold hover-cursor note-item">
                  <div className="pb-2  border-b border-b-[#eeeeec]">
                    <h3 className="text-[13px] font-semibold">
                      The Lost Tapes 2
                    </h3>
                    <p className="text-xs text-gray">
                      His best one(I think or maybe I'm wrong)
                    </p>
                  </div>
                </div>
                <div className="pt-3 pl-6 hover:bg-gold hover-cursor note-item">
                  <div className="pb-2  border-b border-b-[#eeeeec]">
                    <h3 className="text-[13px] font-semibold">
                      Kyle XY Season 4 Script{" "}
                    </h3>
                    <p className="text-xs text-gray">
                      If the producers aren't gonna finish, I will do it myself
                    </p>
                  </div>
                </div>
                <div className="pt-3 pl-6 hover:bg-gold hover-cursor note-item">
                  <div className="pb-2  border-b border-b-[#eeeeec]">
                    <h3 className="text-[13px] font-semibold">
                      Thanos' Shopping List
                    </h3>
                    <p className="text-xs text-gray">
                      He shops for the same stuff I do
                    </p>
                  </div>
                </div>
                <div className="pt-3 pl-6 hover:bg-gold hover-cursor note-item">
                  <div className="pb-2  border-b border-b-[#eeeeec]">
                    <h3 className="text-[13px] font-semibold">Phase 4 plans</h3>
                    <p className="text-xs text-gray">
                      FYI your fav superhero didn't make the cut
                    </p>
                  </div>
                </div>

                <div className="pt-3 pl-6 hover:bg-gold hover-cursor note-item">
                  <div className="pb-2  border-b border-b-[#eeeeec]">
                    <h3 className="text-[13px] font-semibold">
                      100+ reasons Why Kanye is the GOAT
                    </h3>
                    <p className="text-xs text-gray">
                      If you don't agree after the first five points you are
                      just a hater
                    </p>
                  </div>
                </div>

                <div className="pt-3 pl-6 hover:bg-gold hover-cursor note-item">
                  <div className="pb-2  border-b border-b-[#eeeeec]">
                    <h3 className="text-[13px] font-semibold">
                      Conspiracy Theories
                    </h3>
                    <p className="text-xs text-gray">
                      I went to Area 51(It's called Site 15)
                    </p>
                  </div>
                </div>

                <div className="pt-3 pl-6 hover:bg-gold hover-cursor note-item">
                  <div className="pb-2  border-b border-b-[#eeeeec]">
                    <h3 className="text-[13px] font-semibold">C-137 is home</h3>
                    <p className="text-xs text-gray">
                      Doesn't mean explaination but I will anyway
                    </p>
                  </div>
                </div>

                <div className="pt-3 pl-6 hover:bg-gold hover-cursor note-item">
                  <div className="pb-2  border-b border-b-[#eeeeec]">
                    <h3 className="text-[13px] font-semibold">
                      Anime is mid(Spirited Away is great tho)
                    </h3>
                    <p className="text-xs text-gray">
                      A hater's guide to anime
                    </p>
                  </div>
                </div>

                <div className="pt-3 pl-6 hover:bg-gold hover-cursor note-item">
                  <div className="pb-2  border-b border-b-[#eeeeec]">
                    <h3 className="text-[13px] font-semibold">
                      If you've read all the above send me a mail.
                    </h3>
                    <p className="text-xs text-gray">
                      Hit me up, you can find my email in my resume.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-white h-full px-5 py-6 overflow-y-scroll">
            <div className="flex justify-between">
              {!fullScreen ? (
                <img
                  onClick={toggleScreen}
                  src={enlarge}
                  className="w-[20px] h-[20px] hover-cursor"
                />
              ) : (
                <img
                  onClick={toggleScreen}
                  src={compress}
                  className="w-[20px] h-[20px] hover-cursor"
                />
              )}
            </div>
            <div className="mt-5 edit-cursor editor-main">
              <div className="mb-3">
                <h2 className="text-center font-bold text-xl">
                  SARPONG SYLVESTER SARPONG
                </h2>
                <h2 className="text-center font-bold text-[16px]">
                  SOFTWARE ENGINEER
                </h2>
                <h2 className="text-center font-bold text-[13px]">
                  GITHUB:{" "}
                  <a
                    href="https://github.com/Tylerasa"
                    target="_blank"
                    className="text-gold font-normal"
                  >
                    https://github.com/Tylerasa
                  </a>
                </h2>
                <h2 className="text-center font-bold text-[13px]">
                  EMAIL:{" "}
                  <a
                    href="mailto:sarpongasaresylvester@gmail.com"
                    target="_blank"
                    className="text-gold font-normal"
                  >
                    sarpongasaresylvester@gmail.com
                  </a>
                </h2>
                <h2 className="text-center font-bold text-[13px]">
                  WEBSITE:{" "}
                  <a
                    href="https://www.onlysly.com"
                    target="_blank"
                    className="text-gold font-normal"
                  >
                    https://www.onlysly.com
                  </a>
                </h2>
                <h2 className="text-center font-bold text-[13px]">
                  LINKEDIN:{" "}
                  <a
                    href="https://www.linkedin.com/in/sylvester-sarpong-asare"
                    target="_blank"
                    className="text-gold font-normal"
                  >
                    https://www.linkedin.com/in/sylvester-sarpong-asare
                  </a>
                </h2>
              </div>

              {/* education */}
              <div className="text-[13px]">
                <h3 className="border-b uppercase font-semibold">Education</h3>
                <table width="100%">
                  <tr>
                    <td>Kwame Nkrumah University of Science and Technology</td>
                    <td className="text-right">Ashanti Region, Ghana</td>
                  </tr>
                  <tr>
                    <td className="font-semibold">
                      Bachelor of Science, Computer Science
                    </td>
                    <td className="text-right">September 2021</td>
                  </tr>
                </table>
              </div>

              {/* techinal skills */}
              <div className="text-[13px] mt-4">
                <h3 className="border-b uppercase font-semibold">
                  Techincal Skills
                </h3>
                <div>
                  <span className="uppercase font-semibold mr-1">
                    Languages:
                  </span>
                  <span>JavaScript , Typescript , Python , PHP, CSS, SQL.</span>
                </div>
                <div>
                  <span className="uppercase font-bold mr-2">Frameworks:</span>
                  <span>
                    ReactJS, VueJS, Django, Laravel, NodeJS, NestJS, NextJS,
                    Tailwind
                  </span>
                </div>

                <div>
                  <span className="uppercase font-bold mr-2">Databases:</span>
                  <span>MongoDB, MySQL, Firestore, DynamoDB, PostgreSQL.</span>
                </div>

                <div>
                  <span className="uppercase font-bold mr-2">
                    AUTOMATION AND DEVOPS:
                  </span>
                  <span>
                    Docker, Kubernetes, Ansible, Terraform, Github Actions,
                    Stackstorm, ArgoCD.
                  </span>
                </div>

                <div>
                  <span className="uppercase font-bold mr-2">
                    Other Skills:
                  </span>
                  <span>
                    GraphQL, Redux Toolkit, deck.gl, React Native, Jest,
                    Cypress, TypeORM.
                  </span>
                </div>
              </div>

              {/* work experience */}

              <div className="text-[13px] mt-4">
                <h3 className="border-b uppercase font-semibold">
                  WORK EXPERIENCE
                </h3>

                <div>
                  <div className="flex justify-between">
                    <p>
                      <span className="font-semibold">
                        MidLevel DevOps Engineer
                      </span>
                      , TiaCloud, Accra
                    </p>
                    <p className="text-right">October 2023 - Present</p>
                  </div>
                  <ul>
                    <li>
                      • Successfully developed Stackstorm packs to automate the
                      process of Docker image creation and seamlessly hand over
                      control to ArgoCD. Which streamlined our containerization
                      procedures but has also significantly enhanced deployment
                      efficiency while adhering to the principles of GitOps.
                    </li>
                  </ul>
                </div>
                <br />

                {/* Farmerline */}
                <div>
                  <div className="flex justify-between">
                    <p>
                      <span className="font-semibold">Software Engineer</span>,
                      Farmerline, Accra
                    </p>
                    <p className="text-right">June 2022 - October</p>
                  </div>
                  <ul>
                    <li>
                      • Optimized database efficiency through the seamless
                      migration from MySQL to MongoDB, enabling greater
                      flexibility in accommodating dynamic document schema
                      changes.
                    </li>
                    <br />
                    <li>
                      • Enhanced the speed of user activity log migration by
                      implementing a highly efficient scheduled job, executed at
                      midnight, specifically designed to seamlessly transfer
                      logs from MYSQL to MongoDB. This strategic approach
                      significantly accelerated the caching process, ensuring
                      swift retrieval and access to crucial user activity data.
                    </li>
                    <br />

                    <li>
                      • Successfully engineered an advanced implementation of
                      the K-Nearest Neighbors (KNN) algorithm, specifically
                      designed to effectively detect and eliminate outlier
                      coordinates in agricultural land datasets.
                    </li>
                    <br />

                    <li>
                      • Spearheaded the development of a cutting-edge real-time
                      dashboard, leveraging WebSockets and Next.js, to monitor
                      and visualize all activities occurring on Mergdata, the
                      flagship platform of Farmerline. Effectively utilized
                      graph-based data representations to provide a
                      comprehensive and visually rich display of crucial
                      information.
                    </li>
                  </ul>
                </div>
                <br />

                <div className="">
                  <div className="flex justify-between">
                    <p>
                      <span className="font-semibold">Software Engineer</span>,
                      Wi-Flix, Amsterdam
                    </p>
                    <p className="text-right">Sep – Dec 2022</p>
                  </div>
                  <ul>
                    <li>
                      • Optimized database management and advanced user activity
                      tracking by orchestrating the seamless migration of all
                      Firestore collections and Revidd data to a PostgreSQL
                      instance hosted on Google Cloud Platform (GCP). The
                      integration with Google Locker empowered comprehensive
                      data visualization capabilities across all Wi-Flix
                      platforms, including video, mobile, and web, enabling
                      efficient monitoring and analysis of user interactions.
                    </li>
                  </ul>
                </div>

                <br />

                <div className="">
                  <div className="flex justify-between">
                    <p>
                      <span className="font-semibold">
                        Fullstack Developer,
                      </span>
                      , FillyCoder, San Francisco
                    </p>
                    <p className="text-right">Mar - Jul 2022</p>
                  </div>
                  <ul>
                    <li>
                      • Enhanced recruitment efficiency through a comprehensive
                      redesign of the Care in Touch Home Agency website,
                      incorporating a dedicated page specifically tailored for
                      seamless employment applications. The revamped website now
                      provides a user-friendly interface that optimizes the
                      application process, streamlining candidate acquisition
                      and ensuring a more efficient recruitment workflow.
                    </li>
                  </ul>
                </div>
                <br />

                <div className="">
                  <div className="flex justify-between">
                    <p>
                      <span className="font-semibold">
                        Fullstack Developer and Mobile Developer
                      </span>
                      , BøthOfUs, Stockholm
                    </p>
                    <p className="text-right">Nov 2021 – Feb 2022</p>
                  </div>
                  <ul>
                    <li>
                      • Optimized inventory management processes and enhanced
                      accessibility for farmers through the development of a
                      tailored solution. This solution enables seamless storage
                      of inventory data from Excel into Firestore, facilitating
                      real-time access to crucial inventory statistics. By
                      simplifying the data transfer process and leveraging
                      Firestore's capabilities, farmers can efficiently manage
                      their inventory and make informed decisions based on
                      up-to-date information.
                    </li>
                  </ul>
                </div>

                <br />

                <div className="">
                  <div className="flex justify-between">
                    <p>
                      <span className="font-semibold">Frontend Developer</span>,
                      MicroBis, Kumasi
                    </p>
                    <p className="text-right">Oct 2020– Jul 2021</p>
                  </div>
                  <ul>
                    <li>
                      • Elevated the usability and performance of an AI-powered
                      backend system by architecting an intuitive user
                      interface. This interface efficiently analyzes
                      user-supplied parameters and employs predictive algorithms
                      to analyze and predict isolates. The developed solution
                      enhances user experience, empowering users to leverage the
                      system's capabilities in a user-friendly manner while
                      benefiting from accurate predictions and insights.
                    </li>
                    <br />
                    <li>
                      • Advanced data visualization capabilities and delivered
                      comprehensive insights by constructing a cutting-edge
                      deck.gl dashboard. Leveraging geographical data, this
                      dashboard effectively clusters and highlights the most
                      prevalent isolates, offering a visually compelling
                      representation of patterns and trends. By employing
                      deck.gl's powerful visualization library, the dashboard
                      enables users to gain deeper insights into the data,
                      facilitating informed decision-making and enhanced
                      understanding of the most common isolates.
                    </li>
                  </ul>
                </div>
              </div>
              <br />
              <br />

              {/* freelance and personal projects */}
              {/* <div className="text-[13px] mt-4">
                <h3 className="border-b uppercase font-semibold">
                  FREELANCE AND PERSONAL PROJECTS
                </h3>
                <div>
                  <div className="flex justify-between">
                    <p>
                      <span className="font-semibold">
                        React setState (VS Code extension)
                      </span>
                    </p>
                  </div>
                  <ul>
                    <li>
                      • Because creating states in react can be tedious
                      (especially with camel case), I created an extension to
                      quickly state.{" "}
                      <a
                        href="https://marketplace.visualstudio.com/items?itemName=tylerasa.react-setstate"
                        target="_blank"
                        className="text-gold hover-cursor"
                      >
                        Download extension here
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="mt-2">
                  <div className="flex justify-between">
                    <p>
                      <span className="font-semibold">
                        Fullstack Developer{" "}
                      </span>
                      , FillyCoder, San Francisco
                    </p>
                    <p className="text-right">Mar – Jul 2022</p>
                  </div>
                  <ul>
                    <li>
                      • Redesigned the care in touch home agency website and
                      included a page to take employment applications.
                    </li>
                    <li>
                      • Developed an admin dashboard to manage job applications
                      and created an edit option to assist the project owner in
                      making changes to the website themselves. (
                      <b>Tech Stack:</b>
                      ReactJS, NodeJS, Firebase)
                    </li>
                  </ul>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotesWindow;

{
  /* <table width="100%">
<tr>
  <td>
    <span className="font-semibold">
      Fullsatck Developer(Laravel)
    </span>
    , Farmerline, Accra
  </td>
  <td className="text-right w-[200px]">
    June 2022 - Present
  </td>
</tr>
<tr colspan="2">
  <td className="">
    <ul>
      <li>
        • Rewrote eloquent model application logic from
        MariaDB to MongoDB to account for potential flexible
        document schema changes.
      </li>
    </ul>
  </td>
</tr>
</table> */
}
