import React, { useEffect } from "react";
import downloads from "../assets/images/downloads.png";
import pictures from "../assets/images/pictures.png";
import documents from "../assets/images/documents.png";
import desktop from "../assets/images/desktop.png";
import appstore from "../assets/images/appstore.png";
import system from "../assets/images/system.png";
import right_arrow from "../assets/images/right_arrow.png";
import left_arrow from "../assets/images/left_arrow.png";
import { ReactComponent as Word } from "../assets/svgs/word.svg";
import { useDispatch, useSelector } from "react-redux";
import $ from "jquery";
import plus from "../assets/images/plus.png";
import subtract from "../assets/images/subtract.png";
import close from "../assets/images/close.png";
const Window = () => {
  const menu = [
    {
      text: "Application",
      img: appstore,
    },
    {
      text: "Desktop",
      img: desktop,
    },
    {
      text: "Documents",
      img: documents,
    },
    {
      text: "Downloads",
      img: downloads,
    },
    {
      text: "Pictures",
      img: pictures,
    },
  ];

  const { show } = useSelector((state) => state.window);
  const dispatch = useDispatch();
  const window = document.querySelector("#window");

  useEffect(() => {
    if(window){
      if (show) {
        window.classList.add("window");
        window.classList.remove("window--active");
        window.classList.remove("window--minimized");

        window.classList.remove("window--closed");
      } else {

        window.classList.add("window--minimized");
      }
    }
   
  }, [show]);

  const minimize = () => {
    window.classList.add("window--minimized");
  };

  const maximize = () => {
    if (!$(window).hasClass("window--maximized")) {
      window.classList.add("window--maximized");
    } else {
      window.classList.remove("window--maximized");
      window.classList.add("window");
    }
  };

  const cancel = () => {
    if (window) {
      window.classList.add("window--closed");
      window.classList.remove("window--active");
      window.classList.remove("window");
    }
  };

  return (
    <div
      id="window"
      className={`w-[900px] bg-windowHeader h-[500px] hidden absolute rounded-xl`}
    >
      <div className="flex  py-2 border-b border-b-windowBorder fixed w-full rounded-tl-xl rounded-tr-xl bg-white z-10 items-center">
        <div className="flex items-center p-2">
          <span
            onClick={cancel}
            className="w-[13px] h-[13px] bg-close rounded-full  flex items-center justify-center window-control"
          >
            <img src={close} className="w-[8px] h-[8px] hidden" />
          </span>
          <span
            onClick={minimize}
            className="w-[13px] h-[13px] bg-minimise  rounded-full mx-2 flex items-center justify-center window-control"
          >
            <img src={subtract} className="w-[8px] h-[8px] hidden" />
          </span>
          <span
            onClick={maximize}
            className="w-[13px] h-[13px] bg-maximise  rounded-full flex items-center justify-center window-control"
          >
            <img src={plus} className="w-[8px] h-[8px] hidden" />
          </span>
        </div>
        <div className="flex justify-around ml-10">
          <img
            className="w-[18px] h-[18px] mr-1 hover-cursor"
            src={left_arrow}
          />
          <img
            className="w-[18px] h-[18px] mr-1 hover-cursor"
            src={right_arrow}
          />
        </div>
      </div>
      <div className="h-full">
        <div className="grid grid-cols-[20%_80%] rounded-tl-xl pt-10 h-full ">
          <div className="bg-sidebarBg  p-4  border-r border-r-windowBorder">
            <p className="text-xs text-[#b0b0b0] font-semibold">Devices</p>
            <div className="-full p-2 hover:bg-[#dcdcdc] flex hover-cursor hover:transition-all items-center  text-sm rounded-md">
              <img className="w-[25x] h-[25px] mr-1" src={system} />
              <span>System</span>
            </div>
            <div className="mt-4">
              <p className="text-xs text-[#b0b0b0] font-semibold">Favorites</p>
              {menu.map((ele, i) => (
                <div className="w-full p-2 hover:bg-[#dcdcdc] flex hover-cursor hover:transition-all items-center  text-sm rounded-md">
                  <img className="w-[25x] h-[25px] mr-1" src={ele.img} />
                  <span>{ele.text}</span>
                </div>
              ))}
            </div>
          </div>

          <div className="bg-white px-5 py-2">
            <div className="w-[100px] flex flex-col items-center">
              <Word className="w-[60px] h-[60px]" />
              <p className="text-xs  text-black">Resume</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

{
  /* <div className="flex items-center absolute left-[50%] translate-x-[-50%]">
<img src={folder} className="w-[15px] h-[15px] mr-1" />
<p>Stuff</p>
</div> */
}

export default Window;
