import React from "react";
import { ReactComponent as Photos } from "../assets/svgs/photos.svg";
import { ReactComponent as Finder } from "../assets/svgs/finder.svg";
import { ReactComponent as Messages } from "../assets/svgs/messages.svg";
import { useDispatch, useSelector } from "react-redux";
// import notes from "../assets/images/notes.png";
import bin from "../assets/images/bin.png";
import spotify from "../assets/images/spotify.png";
import discord from "../assets/images/discord.png";
import firefox from "../assets/images/firefox.png";
import terminal from "../assets/images/terminal.png";
import vscode from "../assets/images/vscode.png";
import notes from "../assets/svgs/notes.svg";
import finder from "../assets/images/finder.png";
import launchpad from "../assets/images/launchpad.png";
import { showNotes, showWindow } from "../redux/windowSlice";
import { setCurrentApp, setCurrentSettings } from "../redux/appSlice";
import { toast } from "sonner";
const Dock = () => {
  const disptach = useDispatch();

  const { show } = useSelector((state) => state.window);
  const { snotes } = useSelector((state) => state.window);
  

  const notesTexts = [
    "Double-click the desktop Notes icon or click the dock icon to view my resume.",
    "Access my resume by double-clicking Notes on the desktop or clicking the Notes icon in the dock.",
    "View my resume: double-click Notes on the desktop or click the Notes icon in  the dock.",
    "To see my resume, double-click the desktop's Notes icon or click the Notes icon in dock.",
    "Click either the desktop Notes icon or the Notes icon in the dock to view my resume.",
    "Open my resume by double-clicking Notes on the desktop or clicking the Notes icon down here.",
  ]

  const toggleNotes = () => {
    disptach(showNotes(!snotes));
    if (snotes) {
      disptach(setCurrentApp("Finder"));
      disptach(
        setCurrentSettings(["File", "Edit", "View", "Go", "Windows", "Help"])
      );
    } else {
      disptach(setCurrentApp("Notes"));
      disptach(
        setCurrentSettings(["File", "Edit", "Format", "View", "Window", "Help"])
      );
    }
  };

  const notesInfo = () => {
    toast(
      <div className="text-normal">
        <div className="flex justify-between mb-1 items-center">
          <div className="flex gap-1 items-center ">
            <Messages className="w-[18px] h-[18px]" />
            <p>MESSAGES</p>
          </div>
          <p>now</p>
        </div>
        <h3 className="font-bold mb-1 ">Sylvester</h3>
        <p className="leading-4">
          {notesTexts[parseInt(Math.random()*notesTexts.length)]}
        </p>
      </div>
    );
  };
  return (
    <div className="w-auto  mx-auto">
      <div
        // absolute
        //
        // left-[50%] translate-x-[-50%]
        className=" w-full no-select  dock-glass px-1
    
    bottom-[10px]  h-[60px] rounded-xl flex justify-center items-center"
      >
        <div onClick={notesInfo} className="flex flex-col items-center ">
          <img src={finder} className="w-[43px] h-[43px] icon hover-cursor  " />
          <div className="w-[5px] h-[5px] bg-transparent rounded-full "></div>
        </div>

        <div onClick={notesInfo} className="flex flex-col items-center ">
          <img
            src={launchpad}
            className="w-[43px] h-[43px] icon hover-cursor  "
          />
          <div className="w-[5px] h-[5px] bg-transparent rounded-full "></div>
        </div>
        <div onClick={notesInfo} className="flex flex-col items-center ">
          <img
            src={spotify}
            className="w-[43px] h-[43px] icon hover-cursor  "
          />
          <div className="w-[5px] h-[5px] bg-transparent rounded-full "></div>
        </div>
        <div onClick={notesInfo} className="flex flex-col items-center ">
          <img
            src={firefox}
            className="w-[43px] h-[43px] icon hover-cursor  "
          />
          <div className="w-[5px] h-[5px] bg-transparent rounded-full "></div>
        </div>
        <div onClick={notesInfo} className="flex flex-col items-center ">
          <img src={vscode} className="w-[43px] h-[43px] icon hover-cursor  " />
          <div className="w-[5px] h-[5px] bg-transparent rounded-full "></div>
        </div>
        <div onClick={notesInfo} className="flex flex-col items-center px-2">
          <div className="h-[30px] border-l border-l-[#ddd]"></div>
        </div>
        <div onClick={notesInfo} className="flex flex-col items-center ">
          <img
            src={terminal}
            className="w-[43px] h-[43px] icon hover-cursor  "
          />
          <div className="w-[5px] h-[5px] bg-transparent rounded-full "></div>
        </div>
        <div className="flex flex-col items-center ">
          <img
            onClick={toggleNotes}
            src={notes}
            className="w-[43px] h-[43px] icon hover-cursor  "
          />
          <div className="w-[5px] h-[5px] bg-white rounded-full "></div>
        </div>

        <div onClick={notesInfo} className="flex flex-col items-center ">
          <img
            src={discord}
            className="w-[43px] h-[43px] icon hover-cursor  "
          />
          <div className="w-[5px] h-[5px] bg-transparent rounded-full "></div>
        </div>
        <div onClick={notesInfo} className="flex flex-col items-center px-2">
          <div className="h-[30px] border-l border-l-[#ddd]"></div>
        </div>

        <div onClick={notesInfo} className="flex flex-col items-center">
          <img src={bin} className="w-[43px] h-[43px] icon hover-cursor  " />
          <div className="w-[5px] h-[5px] bg-transparent rounded-full "></div>
        </div>
      </div>
    </div>
  );
};

export default Dock;
